const categoryListDb = [
  {
    icon: "fas fa-gifts",
    family: "font-awesome",
    name: "Noel",
  },
  {
    icon: "time_to_leave",
    family: "material-icons",
    name: "Automobiles",
  },
  {
    icon: "child_care",
    family: "material-icons",
    name: "Bébé & Maman",
  },
  {
    icon: "videogame_asset",
    family: "material-icons",
    name: "Jeux & Jouets",
  },
  {
    icon: "face_retouching_natural",
    family: "material-icons",
    name: "Beauté",
  },
  {
    icon: "fas fa-heartbeat",
    family: "font-awesome",
    name: "Bien-être",
  },
  {
    icon: "handyman",
    family: "material-icons",
    name: "Bricolage",
  },
  {
    icon: "fas fa-dog",
    family: "font-awesome",
    name: "Chien",
  },
  {
    icon: "fas fa-cat",
    family: "font-awesome",
    name: "Chat",
  },
  {
    icon: "fitness_center",
    family: "material-icons",
    name: "Fitness",
  },
  {
    icon: "park",
    family: "material-icons",
    name: "Jardinage",
  },
  {
    icon: "sailing",
    family: "material-icons",
    name: "Pêche",
  },
  {
    icon: "fas fa-archive",
    family: "font-awesome",
    name: "Rangement",
  },
  {
    icon: "soup_kitchen",
    family: "material-icons",
    name: "Cuisine",
  },
  {
    icon: "self_improvement",
    family: "material-icons",
    name: "Spiritualité & Yoga",
  },
  {
    icon: "fas fa-hand-sparkles",
    family: "font-awesome",
    name: "Ménage & Nettoyage",
  },
];

const productColumns = [
  "id",
  "Produit",
  "Images",
  "Date de création",
  "Lien_AliExpress",
  "Niche",
  "Nombre_de_commandes",
  "Nombres_d'avis clients",
  "Note",
  "Lien_Publicité",
  "Video_Winners_Club",
  "Miniature_Winners_Club",
  "Keywords",
  "Visible",
  "productNameEn",
];

const StatusText = {
  active: "active",
  active_coupon: "Active Coupon",
  past_due: "Retard paiement",
  unpaid: "Impayé",
  canceled: "annulé",
  incomplete: "Paiement incomplet",
  trialing: "Trialing",
  incomplete_expired: "Paiement incomplet expiré",
};

const DEBOUNCE_TYPING_DELAY = 300;

const videoColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "ID",
    type: "text",
    db: "Lien",
    required: true,
  },
  {
    text: "Page",
    type: "text",
    db: "Page",
    required: false,
  },
  {
    text: "ordre",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const tutorialColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "ID",
    type: "text",
    db: "Lien",
    required: true,
  },
  {
    text: "ordre",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const marketingColumns = [
  {
    text: "Nom",
    required: true,
    type: "text",
    db: "Name",
  },
  {
    text: "Nom en anglais",
    required: true,
    type: "text",
    db: "Name_EN",
  },
  {
    text: "Niche",
    type: "text",
    required: true,
    db: "Niche",
  },
  {
    text: "Image",
    type: "file",
    required: true,
    db: "Image",
  },
  {
    text: "Lien",
    type: "text",
    required: false,
    db: "Link",
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const videoMarketingColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "ID",
    type: "text",
    db: "Lien",
    required: true,
  },
  {
    text: "ordre",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const lienMarketingColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "Titre en anglais",
    type: "text",
    db: "Title_EN",
    required: true,
  },
  {
    text: "Lien",
    type: "link",
    db: "Link",
    required: true,
  },
  {
    text: "order",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Niche",
    type: "text",
    required: true,
    db: "Niche",
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const miniInfluColumns = [
  {
    text: "Nom",
    required: true,
    type: "text",
    db: "Name",
  },
  {
    text: "Nom en anglais",
    required: true,
    type: "text",
    db: "Name_EN",
  },
  {
    text: "Niche",
    type: "text",
    required: true,
    db: "Niche",
  },
  {
    text: "Image",
    type: "file",
    required: true,
    db: "Image",
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const videoMiniInfluColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "ID",
    type: "text",
    db: "Lien",
    required: true,
  },
  {
    text: "ordre",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const lienMiniInfluColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "Titre en anglais",
    type: "text",
    db: "Title_EN",
    required: true,
  },
  {
    text: "Lien",
    type: "link",
    db: "Link",
    required: true,
  },
  {
    text: "order",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Niche",
    type: "text",
    required: true,
    db: "Niche",
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const ebookColumns = [
  {
    text: "Nom",
    required: true,
    type: "text",
    db: "Name",
  },
  {
    text: "Nom en anglais",
    required: true,
    type: "text",
    db: "Name_EN",
  },
  {
    text: "Niche",
    type: "text",
    required: true,
    db: "Niche",
  },
  {
    text: "Lien",
    type: "link",
    required: true,
    db: "Link",
  },
  {
    text: "Image",
    type: "file",
    required: true,
    db: "Image",
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const videoEbookColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "ID",
    type: "text",
    db: "Lien",
    required: true,
  },
  {
    text: "order",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

const testimonyColumns = [
  {
    text: "Titre",
    type: "text",
    db: "Title",
    required: true,
  },
  {
    text: "video ID",
    type: "text",
    db: "Lien",
    required: true,
  },
  {
    text: "order",
    type: "number",
    db: "Role",
    required: true,
  },
  {
    text: "Image",
    type: "file",
    required: true,
    db: "Image",
  },
  {
    text: "Visible",
    type: "checkbox",
    db: "VisibleAllTime",
    required: true,
  },
];

module.exports = {
  categoryListDb,
  productColumns,
  DEBOUNCE_TYPING_DELAY,
  StatusText,
  videoColumns,
  tutorialColumns,
  marketingColumns,
  videoMarketingColumns,
  lienMarketingColumns,
  miniInfluColumns,
  videoMiniInfluColumns,
  lienMiniInfluColumns,
  ebookColumns,
  videoEbookColumns,
  testimonyColumns,
};
